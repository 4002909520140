import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer'
import Header from '@/components/header/header';
import {contact_topic,product,apiGet,apiPost,sendContact,_ok,_err} from '../../services/api'
const captchagen = require('captchagen');

// import "jquery-ui";
// import "jquery-ui-css";
export default {
    name: "Contact",
    data () {
      return {
        titleList: null,
        productList: null,
        name: '',
        line_id: '',
        company: '',
        product_id: '',
        occupation: '',
        contact_topic_id: '',
        email: '',
        detail: '',
        telephone: '',
        subject: '',
        chk: false,
        chk_func: true,
        // eslint-disable-next-line
        reg_email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        txt:'',
        captchaUri: '',
        captchaText: '',
        captcha: '',
        center:{lat:13, lng:101},
        markers:[
            {
                name:'เอจีซี สำนักงานใหญ่ และโรงงาน เอจีซี จ.สมุทรปราการ',
                addr:`200 หมู่1 ถนนสุขสวัสดิ์ ตำบลปากคลองบางปลากด อำเภอพระสมุทรเจดีย์ จังหวัด สมุทรปราการ 10290
                เวลาทำการ 8.00 - 17.00 น.
                โทร : 0-2815-5000
                แฟกซ์ : 0-2815-7385`,
                position:{lat:13.6042287,lng:100.5562787},
                link:"https://www.google.com/maps/place/%E0%B8%9A%E0%B8%A3%E0%B8%B4%E0%B8%A9%E0%B8%B1%E0%B8%97+%E0%B9%80%E0%B8%AD%E0%B8%88%E0%B8%B5%E0%B8%8B%E0%B8%B5+%E0%B9%81%E0%B8%9F%E0%B8%A5%E0%B8%97%E0%B8%81%E0%B8%A5%E0%B8%B2%E0%B8%AA+(%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B9%80%E0%B8%97%E0%B8%A8%E0%B9%84%E0%B8%97%E0%B8%A2)+%E0%B8%88%E0%B8%B3%E0%B8%81%E0%B8%B1%E0%B8%94+(%E0%B8%A1%E0%B8%AB%E0%B8%B2%E0%B8%8A%E0%B8%99)/@13.6047344,100.5553106,19z/data=!4m9!1m2!2m1!1z4LmA4Lit4LiI4Li14LiL4Li1IOC4quC4s-C4meC4seC4geC4h-C4suC4meC5g-C4q-C4jeC5iCA!3m5!1s0x30e2a720a6454e2d:0x63781d3340967ea4!8m2!3d13.604291!4d100.5562971!15sCjfguYDguK3guIjguLXguIvguLUg4Liq4Liz4LiZ4Lix4LiB4LiH4Liy4LiZ4LmD4Lir4LiN4LmIWjwiOuC5gOC4rSDguIjguLUg4LiL4Li1IOC4quC4s-C4meC4seC4geC4h-C4suC4mSDguYPguKvguI3guYiSAQ9mdXJuaXR1cmVfc3RvcmWaASNDaFpEU1VoTk1HOW5TMFZKUTBGblNVTnpiMlV5ZWtoQkVBRQ?hl=th"
            },
            {
                name:'โรงงาน เอจีซี จ.ชลบุรี',
                addr:`700/22 หมู่ 6 ต. หนองไม้แดง อ. เมือง จ. ชลบุรี 20000
                เวลาทำการ 8.00 - 17.00 น.
                โทร : 0-3821-3063
                แฟกซ์ : 0-3821-3066`,
                position:{lat:13.4251721,lng:101.0186613},
                link:"https://www.google.com/maps/place/%E0%B8%9A%E0%B8%A3%E0%B8%B4%E0%B8%A9%E0%B8%B1%E0%B8%97+%E0%B9%80%E0%B8%AD%E0%B8%88%E0%B8%B5%E0%B8%8B%E0%B8%B5+%E0%B9%81%E0%B8%9F%E0%B8%A5%E0%B8%97%E0%B8%81%E0%B8%A5%E0%B8%B2%E0%B8%AA+(%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B9%80%E0%B8%97%E0%B8%A8%E0%B9%84%E0%B8%97%E0%B8%A2)+%E0%B8%88%E0%B8%B3%E0%B8%81%E0%B8%B1%E0%B8%94+(%E0%B8%A1%E0%B8%AB%E0%B8%B2%E0%B8%8A%E0%B8%99)/@13.4247491,101.0179075,19.01z/data=!4m19!1m13!4m12!1m4!2m2!1d100.572213!2d13.8068423!4e1!1m6!1m2!1s0x311d3760ab10408b:0x428636c55952ed11!2zNzAwLzIyIOC4q-C4oeC4ueC5iCA2IOC4lS4g4Lir4LiZ4Lit4LiH4LmE4Lih4LmJ4LmB4LiU4LiHIOC4rS4g4LmA4Lih4Li34Lit4LiHIOC4iC4g4LiK4Lil4Lia4Li44Lij4Li1IDIwMDAw!2m2!1d101.0186708!2d13.4249239!3m4!1s0x311d3760ab10408b:0x428636c55952ed11!8m2!3d13.4249239!4d101.0186708"
            },
            {
                name:'โรงงาน เอจีซี จ.ระยอง',
                addr:`7/104 หมู่ 4 ทางหลวงหมายเลข 331 ต. มาบยางพร อ.ปลวกแดง จ. ระยอง 21140
                เวลาทำการ 8.00 - 17.00 น.
                โทร : 0-3801-6888
                แฟกซ์ : 0-3801-6899`,
                position:{lat:12.9904088,lng:101.1009019},
                link:"https://www.google.com/maps/place/%E0%B8%9A%E0%B8%A3%E0%B8%B4%E0%B8%A9%E0%B8%B1%E0%B8%97+%E0%B9%80%E0%B8%AD%E0%B8%88%E0%B8%B5%E0%B8%8B%E0%B8%B5+%E0%B9%81%E0%B8%9F%E0%B8%A5%E0%B8%97%E0%B8%81%E0%B8%A5%E0%B8%B2%E0%B8%AA+(%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B9%80%E0%B8%97%E0%B8%A8%E0%B9%84%E0%B8%97%E0%B8%A2)+%E0%B8%88%E0%B8%B3%E0%B8%81%E0%B8%B1%E0%B8%94+(%E0%B8%A1%E0%B8%AB%E0%B8%B2%E0%B8%8A%E0%B8%99)+%E0%B9%82%E0%B8%A3%E0%B8%87%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B8%A3%E0%B8%B0%E0%B8%A2%E0%B8%AD%E0%B8%87/@12.9906498,101.1009585,18.39z/data=!4m19!1m13!4m12!1m4!2m2!1d100.5633683!2d13.8132789!4e1!1m6!1m2!1s0x3102c3b0f47229c1:0xa4548a6adf5f0dc0!2z4LmC4Lij4LiH4LiH4Liy4LiZIOC5gOC4reC4iOC4teC4i-C4tSDguIgu4Lij4Liw4Lii4Lit4LiH!2m2!1d101.1016393!2d12.9904836!3m4!1s0x3102c3b0f47229c1:0xa4548a6adf5f0dc0!8m2!3d12.9904836!4d101.1016393"
            },
        ]
      }
    },
    methods: {
        isEmailValid: function() {
            return (this.email == "")? "" : (this.reg_email.test(this.email)) ? 'has-success' : 'has-error';
        },
        chkCaptcha: function() {
            return (this.email == "")? "" : (this.reg_email.test(this.email)) ? 'has-success' : 'has-error';
        },
        acceptNumber() {
            var x = this.telephone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.telephone = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
            // this.telephone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        async sendForm() {
            this.txt = ''
            if(this.captcha != this.captchaText){
                _err("Captcha don't match")
                return false
            }
            if(!this.name||!this.product_id||!this.chk) return false
            if(!this.occupation) return false
            if(!this.contact_topic_id) return false
            if(!this.telephone) return false
            if(this.email && this.isEmailValid()=='has-error') {
                this.chk_func = false
                this.txt = "this email is wrong format "
                return false
            }
            // console.log(this.email,this.isEmailValid())
            // return false
            // eslint-disable-next-line
            let data = {
                name : this.name,
                line_id : this.line_id||'',
                product_id : this.product_id,
                company : this.company||'',
                occupation : this.occupation||'',
                contact_topic_id : this.contact_topic_id||'',
                email : this.email||'',
                detail : this.detail||'',
                telephone : this.telephone||'',
            }
            let res = await apiPost(sendContact,data)
            if(res.insertId){
                this.name = null
                this.line_id = null
                this.product_id = null
                this.company = null
                this.occupation = null
                this.contact_topic_id = null
                this.email = ''
                this.detail = null
                this.telephone = null
                this.chk = false
                this.captcha = ''
                _ok('Processing success');
                // this.$swal(
                //     'Processing success',
                //     'success'
                // );
            }else{
                _err('Something went wrong!')
            }
        },
        getCaptcha(){

            let captcha = captchagen.create();

            this.captchaText = captcha.text();     // Returns captcha text (randomly generated by default)
            captcha.height();   // Returns captcha height (150 by default)
            captcha.width();    // Returns captcha width (300 by default)
            captcha.generate(); // Draws the image to the canvas
    
                /* call `generate()` before running the below */
    
            this.captchaUri = captcha.uri();      // outputs png data-uri. works sync and async (cb is optional)
        },
        zoomout(){
            this.$swal(`<img src="${this.captchaUri}">`)
        },
        gotoMap(evt){
            console.log(evt)
            window.open(evt.link)
           // window.open(`https://www.google.co.th/maps/place/13%C2%B047'11.4%22N+100%C2%B039'12.4%22E/@${evt.lat},${evt.lng},17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xff0969742bb9e076!8m2!3d13.7865116!4d100.6534553?hl=th`)
            // location.href = `https://www.google.co.th/maps/@${evt.lat},${evt.lng},17z?hl=th`
        }
    },
    async mounted () {
        document.title = "AGC - Contact Us";
        AOS.init();
        resize();
        function resize(){
            if(window.innerWidth<800) $('.footer').css({'position':'relative','top':'0px'});
        }

        this.titleList = await apiGet(contact_topic)
        this.productList = await apiGet(product,{
            page : 1,
            limit : 100000000,
            sort : 'ASC',
        })
        this.getCaptcha()
    },
    components: {
        Footer,Header
    }
};